
























































import type { Picture } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

interface FlexibleMaterials {
  title: string
  htmltext: string
  colors: {
    picture: Picture
    label: string
  }[]
  accessories: {
    picture: Picture
    label: string
  }[]
}

export default defineComponent({
  name: 'FlexibleMaterials',
  components: {},
  props: {
    content: {
      type: Object as PropType<FlexibleMaterials>,
      required: true,
    },
  },

  setup() {
    const computeItemsCountClass = (array: unknown[]) => {
      if (Array.isArray(array)) {
        return `items-count-${array.length}`
      }

      return ''
    }

    return {
      computeItemsCountClass,
    }
  },
})
